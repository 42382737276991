import AdminSetting from '../views/admin/AdminSetting.vue';
import ApprovalList from "@/views/approval/ApprovalList.vue";

export const AdminRoutes = [
    {
        path: '/settings',
        name: 'admin.settings',
        component: AdminSetting,
        meta: {
            menuKey: 'setting',
            breadcrumbTitle: '管理者設定',
        },
    },
    {
      path: "/approval",
      name: "admin.approval",
      component: ApprovalList,
      meta: {
          menuKey: "approval",
          breadcrumbTitle: "承認待ち一覧",
      },
  }
];
