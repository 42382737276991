<template>
    <div class="sm:flex gap-x-[20px] form-state sm:px-0 px-[20px]">
        <div class="sm:w-[40%]">
            <div class="px-[40px] bg-[#F0F2F5] pt-[18px] font-bold text-[24px] leading-[32px] text-center">
                広告アカウント <br>
                データベース登録用
            </div>
            <a-form :model="formState" class="!px-[40px] bg-[#F0F2F5] pb-6 pt-3 mb-1">
                <a-typography-text class="!text-[14px] !text-[#333333]">会社名<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                <a-form-item>
                    <a-input v-model:value="formState.company_name" :disabled="isLoading"/>
                    <a-typography-text type="danger" v-if="errorMessage.company_name">
                        {{ errorMessage.company_name[0] }}
                    </a-typography-text>
                </a-form-item>
                <a-typography-text class="!text-[14px] !text-[#333333]">連絡担当者氏名<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                <a-form-item>
                    <a-input v-model:value="formState.contact_person_name" :disabled="isLoading"/>
                    <a-typography-text type="danger" v-if="errorMessage.contact_person_name">
                        {{ errorMessage.contact_person_name[0] }}
                    </a-typography-text>
                </a-form-item>
                <a-typography-text class="!text-[14px] !text-[#333333]">所属部署<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                <a-form-item>
                    <a-input v-model:value="formState.contact_person_job_title" :disabled="isLoading"/>
                    <a-typography-text type="danger" v-if="errorMessage.contact_person_job_title">
                        {{ errorMessage.contact_person_job_title[0] }}
                    </a-typography-text>
                </a-form-item>
                <a-typography-text class="!text-[14px] !text-[#333333]">連絡先メールアドレス<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                <a-form-item>
                    <a-input v-model:value="formState.contact_person_mail_address" :disabled="isLoading"/>
                    <a-typography-text type="danger" v-if="errorMessage.contact_person_mail_address">
                        {{ errorMessage.contact_person_mail_address[0] }}
                    </a-typography-text>
                </a-form-item>
                <a-typography-text class="!text-[14px] !text-[#333333]">連絡先電話番号<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                <a-typography-text class="!text-[12px] !text-[#999999] sm:!inline !block !leading-[28px] ml-1.5">ハイフン( - )を除いて入力してください</a-typography-text>
                <a-form-item>
                    <a-input v-model:value="formState.contact_person_phone_number" :disabled="isLoading"/>
                    <a-typography-text type="danger" v-if="errorMessage.contact_person_phone_number">
                        {{ errorMessage.contact_person_phone_number[0] }}
                    </a-typography-text>
                </a-form-item>
                <div class="flex">
                    <div class="flex-1">
                        <a-typography-text class="!text-[14px] !text-[#333333]">郵便番号<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                        <a-form-item>
                            <a-input v-model:value="formState.postcode" :disabled="isLoading"/>
                            <a-typography-text type="danger" v-if="errorMessage.postcode">
                                {{ errorMessage.postcode[0] }}
                            </a-typography-text>
                            <a-typography-text type="danger" v-if="!errorMessage.postcode && errorMessagePostCode">
                                {{ errorMessagePostCode }}
                            </a-typography-text>
                        </a-form-item>
                    </div>
                    <a-button type="primary" class="ml-4 !mt-[22px]" :disabled="isLoading" @click="getAddressByPostcode">自動入力</a-button>
                </div>
                <a-typography-text class="!text-[14px] !text-[#333333]">都道府県<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                <a-form-item>
                    <a-select
                        v-model:value="formState.prefecture_id"
                        :dropdown-match-select-width="false"
                        style="width: 100%"
                        :options="prefectureOptions"
                        :fieldNames="{label: 'name', value: 'id'}"
                        :disabled="isLoading"
                    >
                    </a-select>
                    <a-typography-text type="danger" v-if="errorMessage.prefecture_id">
                        {{ errorMessage.prefecture_id[0] }}
                    </a-typography-text>
                </a-form-item>
                <a-typography-text class="!text-[14px] !text-[#333333]">市区町村<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                <a-form-item>
                    <a-input v-model:value="formState.address_1" :disabled="isLoading"/>
                    <a-typography-text type="danger" v-if="errorMessage.address_1">
                        {{ errorMessage.address_1[0] }}
                    </a-typography-text>
                </a-form-item>
                <a-typography-text class="!text-[14px] !text-[#333333]">丁目・番地<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                <a-form-item>
                    <a-input v-model:value="formState.address_2" :disabled="isLoading"/>
                    <a-typography-text type="danger" v-if="errorMessage.address_2">
                        {{ errorMessage.address_2[0] }}
                    </a-typography-text>
                </a-form-item>
                <a-typography-text class="!text-[14px] !text-[#333333]">建物名・階数・部屋番号</a-typography-text>
                <a-form-item>
                    <a-input v-model:value="formState.address_3" :disabled="isLoading"/>
                </a-form-item>
            </a-form>
            <a-typography-text type="danger" class="text-right sm:text-left !text-sm">＊は入力必須項目</a-typography-text>
        </div>
        <div class="block sm:hidden border-[#141E46] border-[1px] w-100 my-[39px]"></div>
        <div class="flex-1">
            <div class="w-100 sm:flex gap-x-[10px]">
                <div class="sm:w-[45%] bg-[#F0F2F5] px-[40px] !mb-[20px] sm:!mb-0 sm:px-[30px] pb-6">
                    <div class="pt-[18px] font-bold sm:text-[24px] text-[20px] leading-[32px] pb-[10px] mb-3">プラン情報</div>
                    <a-typography-text class="!text-[14px] !text-[#333333]">プラン選択<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                    <a-form-item>
                        <a-select
                            v-model:value="formState.plan_id"
                            :dropdown-match-select-width="false"
                            class="w-full"
                            :disabled="isLoading"
                        >
                            <a-select-option
                                v-for="(value, key) in optionsPlan"
                                :key="value.id"
                                :value="key.id"
                                :name="value?.plan_name"
                            >
                                {{ value?.plan_name }}
                            </a-select-option>
                        </a-select>
                        <a-typography-text type="danger" v-if="errorMessage.plan_id">
                            {{ errorMessage.plan_id[0] }}
                        </a-typography-text>
                    </a-form-item>
                    <a-typography-text class="!text-[14px] !text-[#333333]">開始日設定<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                    <a-form-item
                        class="!block !mb-0 !pb-6"
                    >
                        <a-date-picker class="w-full" value-format="YYYY/MM/DD" placeholder="" v-model:value="formState.start_date" :disabled="isLoading" :disabled-date="disabledDate">
                            <template #suffixIcon>
                            </template>
                        </a-date-picker>
                        <a-typography-text type="danger" v-if="errorMessage.start_date">
                            {{ errorMessage.start_date[0] }}
                        </a-typography-text>
                    </a-form-item>
                </div>
                <div class="flex-1 bg-[#F0F2F5] px-[40px] pb-6">
                    <div class="pt-[18px] font-bold sm:text-[24px] text-[20px] leading-[32px] pb-[10px] mb-3">広告アカウント管理者</div>
                    <a-typography-text class="!text-[14px] !text-[#333333]">氏名<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                    <a-form-item>
                        <a-input v-model:value="formState.account_name" :disabled="isLoading"/>
                        <a-typography-text type="danger" v-if="errorMessage.account_name">
                            {{ errorMessage.account_name[0] }}
                        </a-typography-text>
                    </a-form-item>
                    <a-typography-text class="!text-[14px] !text-[#333333]">メールアドレス<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                    <a-form-item
                        class="!block !mb-0 !pb-6"
                    >
                        <a-input v-model:value="formState.account_email" :disabled="isLoading"/>
                        <a-typography-text type="danger" v-if="errorMessage.account_email">
                            {{ errorMessage.account_email[0] }}
                        </a-typography-text>
                    </a-form-item>
                </div>
            </div>
            <div class="border-[#141E46] border-[1px] w-100 my-[39px] sm:my-[20px]"></div>
            <div class="w-100 bg-[#FFF5E0] border-[1px] border-[#D9D9D9]">
                <div class="py-[18px] font-bold text-[24px] leading-[32px] text-center">
                    アプリ内 <br>
                    プロフィール掲載用
                </div>
                <div class="px-[40px] sm:flex gap-x-[20px]">
                    <div class="flex-1">
                        <a-typography-text class="text-[14px] text-[#333333]">プロフィール画像  <span class="text-[12px] text-[#999999]">※会社ロゴなど</span></a-typography-text>
                        <div class="upload">
                            <a-upload
                                v-model:file-list="fileList"
                                name="file"
                                list-type="picture-card"
                                class="avatar-uploader !flex items-center justify-center mt-[10px]"
                                :show-upload-list="false"
                                :action="uploadUrl"
                                :before-upload="uploadImage"
                                @change="handleChange"
                                accept=".jpg, .png"
                                :headers="headers"
                                :disabled="isLoading"
                            >
                                <div class="preview-image" v-if="imageUrl">
                                    <img :src="imageUrl" alt="avatar" />
                                </div>
                                <div class="!w-[120px] !h-[120px] !border-solid !m-0 flex items-center justify-center" v-else>
                                    <loading-outlined v-if="loading"></loading-outlined>
                                    <img v-else src="./../../assets/icon_company.svg" alt="avatar" />
                                </div>
                            </a-upload>
                        </div>
                        <div>
                            <a-typography-text class="!text-[14px] !text-[#333333] !leading-[28px]">会社名<a-typography-text type="danger">＊</a-typography-text> <a-typography-text class="!text-[12px] !text-[#999999]">※略称可</a-typography-text></a-typography-text>
                            <a-form-item>
                                <a-input v-model:value="formState.display_name" :disabled="isLoading"/>
                                <a-typography-text type="danger" v-if="errorMessage.display_name">
                                    {{ errorMessage.display_name[0] }}
                                </a-typography-text>
                            </a-form-item>
                        </div>
                        <div>
                            <a-typography-text class="!text-[14px] !text-[#333333] !leading-[28px]">問い合わせ先メールアドレス<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                            <a-form-item>
                                <a-input v-model:value="formState.display_mail_address" :disabled="isLoading"/>
                                <a-typography-text type="danger" v-if="errorMessage.display_mail_address">
                                    {{ errorMessage.display_mail_address[0] }}
                                </a-typography-text>
                            </a-form-item>
                        </div>
                        <div>
                            <a-typography-text class="!text-[14px] !text-[#333333]">問い合わせ先電話番号<a-typography-text type="danger">＊</a-typography-text></a-typography-text>
                            <a-typography-text class="!text-[12px] !text-[#999999] !block !leading-[28px]">※ハイフン( - )を除いて入力してください</a-typography-text>
                            <a-form-item>
                                <a-input v-model:value="formState.display_phone_number" :disabled="isLoading"/>
                                <a-typography-text type="danger" v-if="errorMessage.display_phone_number">
                                    {{ errorMessage.display_phone_number[0] }}
                                </a-typography-text>
                            </a-form-item>
                        </div>
                        <div>
                            <a-typography-text class="!text-[14px] !text-[#333333] !leading-[28px]">自社ホームページ・LPなどのURL</a-typography-text>
                            <a-form-item>
                                <a-input v-model:value="formState.url" :disabled="isLoading"/>
                                <a-typography-text type="danger" v-if="errorMessage.url">
                                    {{ errorMessage.url[0] }}
                                </a-typography-text>
                            </a-form-item>
                        </div>
                        <div>
                            <a-typography-text class="!text-[14px] !text-[#333333] !leading-[28px]">ユーザーへのご挨拶</a-typography-text>
                            <a-form-item>
                                <a-textarea v-model:value="formState.display_description" :disabled="isLoading" class="!min-h-[210px] !resize-none"/>
                                <a-typography-text type="danger" v-if="errorMessage.display_description">
                                    {{ errorMessage.display_description[0] }}
                                </a-typography-text>
                            </a-form-item>
                        </div>
                    </div>
                    <div class="sm:w-[45%]">
                        <div class="text-[13px] text-[#333333] pb-1.5">掲載例</div>
                        <img src="./../../assets/static_image.svg" class="w-full" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex items-center justify-center py-6 mx-[45px]">
        <div class="max-w-[600px] w-full">
            <div class="group-privacy px-3">
                <div class="privacy-item py-1">
                    <a-checkbox @change="privacyTerms = !privacyTerms" :disabled="isLoading" class="!leading-[40px] !items-start !text-[#333333]">広告主規約に同意する<a-typography-text class="block sm:inline">（ <a-typography-link href="javascript:void(0)" class="border-b-[1px] border-[#CD1225] !text-[#CD1225]">広告主規約</a-typography-link>  ）</a-typography-text></a-checkbox>
                </div>
                <div class="privacy-item py-1">
                    <a-checkbox @change="privacyPolicy = !privacyPolicy" :disabled="isLoading" class="!leading-[40px] !items-start !text-[#333333]">プライバシーポリシーに同意する<a-typography-text class="block sm:inline">（ <a-typography-link href="javascript:void(0)" class="border-b-[1px] border-[#CD1225] !text-[#CD1225]">プライバシーポリシー</a-typography-link>  ）</a-typography-text></a-checkbox>
                </div>
                <div class="privacy-item py-1">
                    <a-checkbox @change="privacyGuidelines = !privacyGuidelines" :disabled="isLoading" class="!leading-[40px] !items-start !text-[#333333]">広告ガイドラインに同意する<a-typography-text class="block sm:inline">（ <a-typography-link href="javascript:void(0)" class="border-b-[1px] border-[#CD1225] !text-[#CD1225]">広告ガイドライン</a-typography-link>  ）</a-typography-text></a-checkbox>
                </div>
            </div>
            <div class="pt-4">
                <a-button type="primary" class="w-full !rounded-[2px] !h-[60px] sm:!h-[40px] sm:!text-[16px] !text-[22px] bold btn-register-submit" @click="register" :loading="isLoading" :disabled="!(privacyGuidelines && privacyTerms && privacyPolicy)">登録する</a-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, reactive, ref, inject} from "vue";
import {useApi} from "@/composables/useApi";
import {useRouter} from "vue-router";
import {notification} from 'ant-design-vue';
import {LoadingOutlined} from "@ant-design/icons-vue";

const dayjs = inject('dayjs');

const formState = reactive({
    company_name: "",
    contact_person_name: "",
    contact_person_job_title: "",
    contact_person_phone_number: "",
    contact_person_mail_address: "",
    postcode: "",
    prefecture_id: "",
    address_1: "",
    address_2: "",
    address_3: "",
    avatar: "",
    plan_id: "",
    start_date: "",
    account_email: "",
    account_name: "",
    display_name: "",
    display_mail_address: "",
    display_phone_number: "",
    url: "",
    display_description: ""
});

const privacyTerms = ref(false)
const privacyPolicy = ref(false)
const privacyGuidelines = ref(false)
const optionsPlan = ref({});
const disabledDate = current => {
    return current && current < dayjs().endOf('day');
};
const api = useApi();
const router = useRouter();
const prefectureOptions = ref([]);
const errorMessage = ref({});
const errorMessagePostCode = ref('');
const isLoading = ref(false);
const uploadUrl =  process.env.VUE_APP_ADMIN_DOMAIN + '/api/files/upload';
const headers = ref({
    authorization: 'authorization-text',
    typeFile: 2
});
const imageUrl = ref('');
const fileList = ref([]);
const domainStorage = process.env.VUE_APP_STORAGE_DOMAIN;
const loading = ref(false);

async function register() {
    try {
        isLoading.value = true;
        errorMessage.value = {};
        formState.avatar = imageUrl.value
        await api.auth.post('api/advertisers', formState);
        router.push({name: 'register-complete'});
    } catch (error) {
        if (error?.response?.status === 422) {
            errorMessage.value = error.response.data.errors;
        }
    } finally {
        isLoading.value = false;
    }
}

async function getAddressByPostcode() {
    try {
        isLoading.value = true;
        errorMessagePostCode.value = '';
        const response = await api.withoutAuth.get(`/api/search-zipcode?zipcode=${formState.postcode}`);
		formState.prefecture_id = parseInt(response.data.results[0].prefcode);
		formState.address_1 = response.data.results[0].address2;
		formState.address_2 = response.data.results[0].address3;
    } catch (error) {
        if (error.response.status ===  400) {
            errorMessagePostCode.value = error.response.data.message;
        }
    } finally {
        isLoading.value = false;
    }
}

const showNotification = (type, message, description) => {
    notification[type]({
        message,
        description: description ? description : '',
    });
};

async function getPrefectures() {
    try {
        isLoading.value = true;
        const response = await api.auth.get('/api/masters/m_prefectures');
        prefectureOptions.value = response.data;
    } catch (error) {
      console.error(error);
    } finally {
        isLoading.value = false;
    }
}

async function getMPlan() {
    try {
        const response = await api.auth.get('/api/masters/m_plans');
        optionsPlan.value = response.data;
    } catch (error) {
        console.error(error);
    }
}

const handleChange = (info) => {
    imageUrl.value = '';
    if (!info.file.status) {
        fileList.value = [];
    }
    if (info.file.status === 'uploading') {
        loading.value = true;
        return;
    }
    if (info.file.status === 'done') {
        imageUrl.value = domainStorage + info?.file?.response?.path
        loading.value = false;
        showNotification('success', 'ファイルをアップロードしました');
    }
    if (info.file.status === 'error') {
        imageUrl.value = '';
        fileList.value = [];
        loading.value = false;
        showNotification('error', 'ファイルをアップロードできませんでした');
    }
};

onMounted(async () => {
    await getPrefectures();
    await getMPlan();
})

function uploadImage(file) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    const validSize = file.size < 100 * 1024 * 1024;
    if (!validSize) {
        showNotification(
            'error',
            'ファイルサイズが大きすぎます',
            '1ファイルあたりの最大アップロードサイズは100MBです。'
        );
    } else if (!isJPG) {
        showNotification(
            'error',
            'ファイルの形式が正しくありません',
            '対応するファイル形式はJPG/PNGです。'
        );
    }
    return validSize && isJPG;
}
</script>
<style scoped>

.preview-image {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
}
.preview-image img {
    width: 100%;
    height: auto;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
</style>
<style>

* {
    font-family: 'Noto Sans JP', sans-serif;
}

.upload .ant-upload.ant-upload-select-picture-card {
    border-radius: 50% !important;
}

.form-state .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #CD1225;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
}

.form-state .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: ' ';
}

.group-privacy .ant-checkbox-inner {
    width: 40px;
    height: 40px;
    border: 2px solid #D9D9D9;
    border-radius: 5px;
}

.group-privacy .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ffffff;
    border-color: #D9D9D9;
}

.group-privacy .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #CD1225;
    width: 10px;
    height: 20px;
    border-width: 3px;
    left: 29%;
}

.btn-register-submit[disabled], .btn-register-submit[disabled]:hover {
    background-color: #B1B1B1 !important;
    border-color: #B1B1B1 !important;
    color: #ffffff !important;
}

.group-privacy .privacy-item .ant-checkbox {
    top: 0;
}

@media (max-width: 575px) {
    .form-state .ant-form-item .ant-form-item-label {
        padding: 0;
    }
}

</style>
