<template>
    <a-layout-content :style="{ backgroundColor: '#fff', padding: '20px 24px',  marginBottom: '20px', borderRadius: '6px' }">
        <a-row :gutter="12" align="middle" class="mb-4">
            <a-col>
                <a-input
                    v-model:value="searchParams.search"
                    style="width: 300px"
                    :disabled="isLoading"
                    v-on:keyup.enter="search"
                    v-on:blur="search"
                >
                    <template #prefix>
                        <SearchOutlined />
                    </template>
                </a-input>
            </a-col>
            <a-col>
                <a-divider style="height: 15px; background-color: #d9d9d9" type="vertical" />
            </a-col>
            <FilterOutlined />
            <a-col class="ml-2">
                <a-radio-group
                    v-model:value="searchParams.filter"
                    :disabled="isLoading"
                    button-style="solid"
                >
                    <a-radio-button
                        v-for="item in status"
                        :key="item.value"
                        :value="item.value"
                        @focus="searchStatus(item.value)"
                    >
                        {{ item.text }}
                    </a-radio-button>
                </a-radio-group>
            </a-col>
        </a-row>
        <a-table
            :columns="tableColumns"
            :data-source="advertisers"
            :pagination="pagination"
            :loading="isLoading"
            :locale="{ emptyText: noData }"
            :custom-row="((record) => {
              return {
                onClick: () => router.push('/advertiser/' + record.id),
            }})"
            @resizeColumn="handleResizeColumn"
            @change="handleTableChange"
        >
            <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'status'">
                    <a-tag
                        :color="
                            record.status_id === 2
                                ? 'green'
                                : record.status_id === 3
                                ? 'processing'
                                : 'default'
                        "
                        class="!mr-0"
                    >
                        {{ getStatus(record.status_id) }}
                    </a-tag>
                </template>
                <template v-if="column.key === 'action'">
                    <div class="cursor-pointer" @click="router.push('/advertiser/' + record.id)">
                        <EditOutlined :style="{ color: '#666' }" />
                    </div>
                </template>
                <template v-if="column.key === 'prefecture'">
                    <a-typography-text
                    >{{ record?.m_prefecture?.name }}
                    </a-typography-text
                    >
                </template>
                <template v-if="column.key === 'company_name'">
                    <a-avatar v-if="record.avatar" :src="record.avatar" class="!mr-2 border border-[#b5b5b5]" />
                    <a-typography-text>{{ record?.company_name }}</a-typography-text>
                </template>
            </template>
        </a-table>
    </a-layout-content>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useApi } from "@/composables/useApi";
import router from "@/routers";
import { EditOutlined, SearchOutlined, FilterOutlined } from "@ant-design/icons-vue";

const isLoading = ref(false);
const advertisers = ref([]);
const currentPage = ref(1);
const pageSize = ref(20);
const pagination = ref({});
const searchParams = ref({
    search: '',
    filter: '',
});

const status = [
    {
        value: '2',
        text: '新規申込'
    },
    {
        value: '3',
        text: '利用中',
    },
    {
        value: '4',
        text: '利用休止',
    }
];

const tableColumns = ref([
    {
        title: '会社名',
        dataIndex: 'company_name',
        key: 'company_name',
    },
    {
        title: '都道府県',
        dataIndex: 'prefecture',
        key: 'prefecture',
        width: 120,
    },
    {
        title: '担当者名',
        dataIndex: 'contact_person_name',
        key: 'contact_person_name',
    },
    {
      title: '担当者連絡先',
      dataIndex: 'contact_person_phone_number',
      key: 'contact_person_phone_number',
      width: 180
    },
    {
        title: '状態',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        align: 'center'
    },
    {
        title: '編集',
        key: 'action',
        width: 80,
        align: 'center'
    },
]);
const noData = 'データがありません';
const api = useApi();

function getStatus(statusId) {
    let statusDisplay = '';
    switch (statusId) {
        case 2:
            statusDisplay = status[0].text;
            break;
        case 3:
            statusDisplay = status[1].text;
            break;
        case 4:
            statusDisplay = status[2].text;
            break;
    }

    return statusDisplay;
}

onMounted(async () => {
    await getAdvertisers();
});

function handleResizeColumn(w, col) {
    col.width = w;
}

async function handleTableChange(pag) {
    isLoading.value = true;
    currentPage.value = pag.current;
    pageSize.value = pag.pageSize;

    await getAdvertisers(searchParams.value);
}

async function getAdvertisers() {
    try {
        isLoading.value = true;
        const config = {
            params: {...searchParams.value, page: currentPage.value, per_page: pageSize.value},
        };
        const response = await api.auth.get('/api/advertisers', config);
        advertisers.value = response.data.data;
        pagination.value = {
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
            showTotal: (total) => `合計: ${total}件`,
            showSizeChanger:false
        };
    } catch (error) {
        console.log(error);
    } finally {
        isLoading.value = false;
    }
}

function searchStatus(status) {
    searchParams.value.filter = status;
    currentPage.value = 1;
    getAdvertisers();
}

function search() {
    currentPage.value = 1;
    getAdvertisers();
}
</script>
