<template>
	<a-typography-text v-if="errorMessage">
        {{ errorMessage }}
    </a-typography-text>
    <a-typography-text v-else-if="statusSuccess">
        アカウント認証が完了しました。 <br>

        ご登録いただいた情報をもとに、広告主審査を行います。<br>
        ３営業日以内に審査結果をメールにてお知らせいたしますので、今しばらくお待ちください。<br>

        よろしくお願いします。<br>

        ※ご不明な点がありましたら、弊社ウェブサイト gurukai.co.jpよりお問い合わせください。
    </a-typography-text>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {useApi} from "@/composables/useApi";
import {useRouter} from "vue-router";
const router = useRouter();
const api = useApi();

const errorMessage = ref('');
const statusSuccess = ref(false);

async function verifyMail() {
    try {
        await api.withoutAuth.get('/api/verify-mail/' + router.currentRoute.value.query.token);
        statusSuccess.value = true
    } catch (error) {
        if (error.response.status === 400) {
            errorMessage.value = error.response.data.message;
        }
    }
}

onMounted(async () => {
    await verifyMail();
})
</script>
