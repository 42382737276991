import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useApi } from '@/composables/useApi';

export const useMaster = defineStore('master', () => {
    const api = useApi();

    const itemCategories = ref([]);
    const rejectReasonCategories= ref([]);
    const plans = ref({});
    const prefectures = ref({});
    const token = ref(localStorage.getItem('access_token') || null);

    const getItemCategories = async () => {
        try {
            const response = await api.auth.get('api/items/get-item-category');
            itemCategories.value = response.data;
        } catch (err) {
            console.error(err);
        }
    };

    const getRejectReasonCategories = async () => {
        try {
            const response = await api.auth.get(
                'api/masters/m_reject_categories'
            );
            rejectReasonCategories.value = response.data;
        } catch (err) {
            console.error(err);
        }
    };

    const getPlans = async () => {
        try {
            const response = await api.auth.get('/api/masters/m_plans');
            plans.value = response.data;
        } catch (error) {
            console.error(error);
        }
    };

    const getPrefecture = async () => {
        try {
            const response = await api.auth.get('/api/masters/m_prefectures');
            prefectures.value = response.data;
        } catch (error) {
            console.log(error);
        }
    };

    async function getMasterData() {
        if (!token.value) return;

        try {
            await Promise.all([
                getItemCategories(),
                getRejectReasonCategories(),
                getPlans(),
                getPrefecture(),
            ]);
        } catch (error) {
            console.log(error);
        }
    }

    return {
        itemCategories,
        rejectReasonCategories,
        plans,
        prefectures,
        getMasterData,
    };
});
