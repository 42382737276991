<template>
    <a-layout has-sider>
        <MenuLayout :collapsed="collapsedState.collapsed" />
        <a-layout class="h-screen overflow-y-hidden">
            <HeaderLayout :collapsed="collapsedState.collapsed" :toggleCollapsed="toggleCollapsed"/>
            <a-layout :style="{ padding: '24px', overflowY: 'auto', marginTop: '64px' }">
                <a-layout-content :style="{ margin: 0, minHeight: 'auto' }">
                    <router-view/>
                </a-layout-content>
            </a-layout>
        </a-layout>
    </a-layout>
</template>

<script setup>
import HeaderLayout from "@/views/layout/HeaderLayout.vue";
import MenuLayout from "@/views/layout/MenuLayout.vue";
import { useAuth } from "@/composables/useAuth";

const { collapsedState, toggleCollapsed } = useAuth();
</script>

<style>
@media (max-width: 768px) {
    .menu-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: #00000078;
        top: 0;
        z-index: 99;
    }
}
.site-layout .site-layout-background {
    background: #fff;
}
</style>
