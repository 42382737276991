import Login from '@/views/auth/LoginPage.vue';
import Register from '@/views/auth/RegisterPage.vue';
import SendMailSuccess from '@/views/auth/SendMailSuccess.vue';
import RegisterComplete from '@/views/auth/RegisterComplete.vue';
import LayoutAuth from '@/LayoutAuth.vue';

export const AuthRoutes = [
    {
        path: '/auth',
        component: LayoutAuth,
        children: [
            {
                path: '/auth/login',
                name: 'login',
                component: Login,
                meta: {
                    title: 'ぐるかい ログイン',
                },
            },
            {
                path: '/auth/register',
                name: 'register',
                component: Register,
                meta: {
                    title: 'ぐるかい 新規会員登録',
                },
            },
            {
                path: '/auth/register-complete',
                name: 'register-complete',
                component: SendMailSuccess,
                meta: {
                    title: 'ぐるかい 広告アカウント登録',
                },
            },
            {
                path: '/auth/activate',
                name: 'activate',
                component: RegisterComplete,
                meta: {
                    title: 'ぐるかい 広告アカウント認証',
                },
            },
        ],
    },
];
