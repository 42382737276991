import { useAuth } from "@/composables/useAuth";
import { useMaster } from "@/composables/useMaster";

export default async function auth({ next }) {
    const auth = useAuth()
    const masterStore = useMaster()
    if (!auth.isLoggedIn) {
        await auth.fetchUser();
        if (auth?.authUser?.id) {
            await masterStore.getMasterData();
        }
        if (auth?.authUser?.is_admin) {
            await auth.getTotalMessage();
        }
    }

    if (!auth.isLoggedIn) {
        location.href = process.env.VUE_APP_ADMIN_DOMAIN + '/api/login'
    }

    return next();
}
