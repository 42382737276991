<template>
    <a-form :model="formState" autocomplete="off">
        <a-row class="mb-8">
            <a-col :span="6">
                <a-typography-title :level="4" class="p-2"
                    >基本情報</a-typography-title
                >
            </a-col>
            <a-col :span="18">
                <a-card :bordered="false">
                    <div class="flex mb-6">
                        <div
                            class="w-[250px] flex items-center self-start h-[30px] shrink-0"
                        >
                            <span class="pr-1 pl-[3px]">ステータス</span>
                        </div>
                        <div class="grow-1 self-center">
                            <template v-if="!isLoading">
                                <a-tag
                                    v-if="currentStatus?.color"
                                    :color="currentStatus.color"
                                >
                                    {{ currentStatus.value }}
                                </a-tag>
                                <a-typography-text underline type="danger" class="cursor-pointer" @click="openChangeStatusModal">
                                  <WarningOutlined />
                                  <span>{{ `${changeStatusToLabel}にする` }}</span>
                                </a-typography-text>
                            </template>
                        </div>
                    </div>
                    <a-form-item
                        label="会社名"
                        :colon="false"
                        required
                        :validate-status="
                            isInvalid('company_name') ? 'error' : ''
                        "
                        :help="invalidMessages('company_name')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.company_name"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="連絡担当者"
                        :colon="false"
                        required
                        :validate-status="
                            isInvalid('contact_person_name') ? 'error' : ''
                        "
                        :help="invalidMessages('contact_person_name')[0]"
                        :label-col="labelCol"
                        :wrapper-col="wrapperCol"
                    >
                        <a-input
                            v-model:value="formState.contact_person_name"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="所属部署名"
                        :colon="false"
                        :validate-status="
                            isInvalid('contact_person_job_title') ? 'error' : ''
                        "
                        :help="invalidMessages('contact_person_job_title')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.contact_person_job_title"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="連絡電話番号"
                        :colon="false"
                        required
                        :validate-status="
                            isInvalid('contact_person_phone_number')
                                ? 'error'
                                : ''
                        "
                        :help="
                            invalidMessages('contact_person_phone_number')[0]
                        "
                        :label-col="labelCol"
                        :wrapper-col="{
                            style: {
                                minWidth: '200px',
                                flexGrow: 0,
                            },
                        }"
                    >
                        <a-input
                            v-model:value="
                                formState.contact_person_phone_number
                            "
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="連絡メールアドレス"
                        :colon="false"
                        required
                        :validate-status="
                            isInvalid('contact_person_mail_address')
                                ? 'error'
                                : ''
                        "
                        :help="
                            invalidMessages('contact_person_mail_address')[0]
                        "
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="
                                formState.contact_person_mail_address
                            "
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <div class="flex">
                        <a-form-item
                            label="郵便番号"
                            :colon="false"
                            required
                            :validate-status="
                                isInvalid('postcode') ? 'error' : ''
                            "
                            :help="invalidMessages('postcode')[0]"
                            :label-col="labelCol"
                        >
                            <a-input
                                v-model:value="formState.postcode"
                                :disabled="isLoading"
                                size="large"
                            />
                        </a-form-item>
                        <a-button
                            class="ml-4 !mt-1"
                            :disabled="isLoading"
                            type="primary"
                            @click="getAddressByPostcode"
                        >
                            検索
                        </a-button>
                    </div>
                    <a-form-item
                        label="都道府県"
                        :colon="false"
                        required
                        :validate-status="
                            isInvalid('prefecture_id') ? 'error' : ''
                        "
                        :help="invalidMessages('prefecture_id')[0]"
                        :label-col="labelCol"
                        :wrapper-col="{
                            style: {
                                minWidth: '150px',
                                flexGrow: 0,
                            },
                        }"
                    >
                        <a-select
                            v-model:value="formState.prefecture_id"
                            :dropdown-match-select-width="false"
                            size="large"
                            :disabled="isLoading"
                        >
                            <a-select-option
                                v-for="(value, key) in masterStore.prefectures"
                                :key="value.id"
                                :value="key.id"
                                :name="value.name"
                            >
                                {{ value.name }}
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item
                        label="市区町村"
                        :colon="false"
                        required
                        :validate-status="isInvalid('address_1') ? 'error' : ''"
                        :help="invalidMessages('address_1')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.address_1"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="丁目・番地"
                        :colon="false"
                        required
                        :validate-status="isInvalid('address_2') ? 'error' : ''"
                        :help="invalidMessages('address_2')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.address_2"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="建物名・階数・部屋番号"
                        :colon="false"
                        :validate-status="isInvalid('address_3') ? 'error' : ''"
                        :help="invalidMessages('address_3')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.address_3"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                </a-card>
            </a-col>
        </a-row>
        <a-row class="mb-8">
            <a-col :span="6">
                <a-typography-title :level="4" class="p-2"
                    >プラン情報</a-typography-title
                >
            </a-col>
            <a-col :span="18">
                <a-card :bordered="false">
                    <a-form-item
                        label="プラン名"
                        :colon="false"
                        :label-col="labelCol"
                        :validate-status="isInvalid('plan_id') ? 'error' : ''"
                        :help="invalidMessages('plan_id')[0]"
                        :wrapper-col="{
                            style: {
                                minWidth: '200px',
                                flexGrow: 0,
                            },
                        }"
                    >
                        <a-select
                            v-model:value="formState.plan_new.plan_id"
                            :dropdown-match-select-width="false"
                            :disabled="isLoading"
                            size="large"
                            @change="handleChangePlan"
                        >
                            <a-select-option
                                v-for="(value, key) in masterStore.plans"
                                :key="value.id"
                                :value="key.id"
                                :name="value.plan_name"
                            >
                                {{ value.plan_name }}
                            </a-select-option>
                        </a-select>
                        <a-typography-text v-if="planMessage" class="pt-1 whitespace-nowrap">{{ planMessage }}</a-typography-text>
                    </a-form-item>
                    <a-form-item
                        label="開始日"
                        :colon="false"
                        :label-col="labelCol"
                        :validate-status="
                            isInvalid('start_date') ? 'error' : ''
                        "
                        :help="invalidMessages('start_date')[0]"
                        :wrapper-col="{
                            style: {
                                minWidth: '200px',
                                flexGrow: 0,
                            },
                        }"
                    >
                        <a-date-picker
                            :value-format="dateFormat"
                            v-model:value="formState.plan_new.start_date"
                            :disabled="isLoading"
                            v-if="statusChangePlan"
                            size="large"
                            :disabled-date="disabledDate"
                        />
                        <a-typography-text v-else>{{
                            formState.plan_new.start_date ? dayjs(formState.plan_new.start_date).format('YYYY年M月D日') : ''
                        }}</a-typography-text>
                    </a-form-item>
                </a-card>
            </a-col>
        </a-row>
        <a-row class="mb-8">
            <a-col :span="6">
                <a-typography-title :level="4" class="p-2"
                    >広告アカウント管理者</a-typography-title
                >
            </a-col>
            <a-col :span="18">
                <a-card :bordered="false">
                    <a-form-item
                        label="氏名"
                        :colon="false"
                        :validate-status="isInvalid('name') ? 'error' : ''"
                        :help="invalidMessages('name')[0]"
                        :label-col="labelCol"
                        :wrapper-col="wrapperCol"
                    >
                        <a-input
                            v-model:value="formState.admin_user.name"
                            :disabled="isLoading || formState.status_id != 2"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="メールアドレス"
                        :colon="false"
                        :validate-status="
                            isInvalid('mail_address') ? 'error' : ''
                        "
                        :help="invalidMessages('mail_address')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.admin_user.mail_address"
                            :disabled="isLoading || formState.status_id != 2"
                            size="large"
                        />
                    </a-form-item>
                </a-card>
            </a-col>
        </a-row>
        <a-row class="mb-8">
            <a-col :span="6">
                <a-typography-title :level="4" class="px-2 pt-2 !mb-1"
                    >広告主ページ設定</a-typography-title
                >
                <a-typography-text type="secondary" class="px-2"
                    >※ PR商品を閲覧するユーザーに公開されます</a-typography-text
                >
            </a-col>
            <a-col :span="18">
                <a-card :bordered="false">
                    <div class="flex mb-7">
                        <div class="w-[250px] h-[30px] shrink-0">
                            <span class="pr-1">ロゴ</span>
                        </div>
                        <div class="upload">
                            <a-upload
                                v-model:file-list="fileList"
                                name="file"
                                list-type="picture-card"
                                class="avatar-uploader !flex items-center justify-center"
                                :show-upload-list="false"
                                :action="uploadUrl"
                                :before-upload="uploadImage"
                                @change="handleChange"
                                accept=".jpg, .png"
                                :headers="headers"
                                :disabled="isLoading"
                            >
                                <div class="h-full" v-if="imageUrl">
                                    <img :src="imageUrl" alt="avatar" class="rounded-full object-cover w-full h-full" />
                                </div>
                                <div class="!w-[120px] !h-[120px] !border-solid !m-0 flex items-center justify-center" v-else>
                                    <loading-outlined v-if="loading"></loading-outlined>
                                    <img v-else src="./../../assets/icon_company.svg" alt="avatar" />
                                </div>
                            </a-upload>
                        </div>
                    </div>
                    <a-form-item
                        label="会社名"
                        :colon="false"
                        :validate-status="
                            isInvalid('display_name') ? 'error' : ''
                        "
                        :help="invalidMessages('display_name')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.display_name"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="メールアドレス"
                        :colon="false"
                        :validate-status="
                            isInvalid('display_mail_address') ? 'error' : ''
                        "
                        :help="invalidMessages('display_mail_address')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.display_mail_address"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="電話番号"
                        name="displayPhoneNumber"
                        :colon="false"
                        :validate-status="
                            isInvalid('display_phone_number') ? 'error' : ''
                        "
                        :help="invalidMessages('display_phone_number')[0]"
                        :label-col="labelCol"
                        :wrapper-col="{
                            style: {
                                minWidth: '200px',
                                flexGrow: 0,
                            },
                        }"
                    >
                        <a-input
                            v-model:value="formState.display_phone_number"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                    <a-form-item
                        label="説明"
                        name="description"
                        :colon="false"
                        :validate-status="
                            isInvalid('display_description') ? 'error' : ''
                        "
                        :help="invalidMessages('display_description')[0]"
                        :label-col="labelCol"
                    >
                        <a-textarea
                            v-model:value="formState.display_description"
                            :disabled="isLoading"
                            size="large"
                            :auto-size="{ minRows: 5 }"
                        />
                    </a-form-item>
                    <a-form-item
                        label="URL"
                        :colon="false"
                        :validate-status="isInvalid('url') ? 'error' : ''"
                        :help="invalidMessages('url')[0]"
                        :label-col="labelCol"
                    >
                        <a-input
                            v-model:value="formState.url"
                            :disabled="isLoading"
                            size="large"
                        />
                    </a-form-item>
                </a-card>
            </a-col>
        </a-row>
        <a-row class="mb-8">
            <a-col :span="6">
                <a-typography-title :level="4" class="p-2"
                    >プラン変更履歴</a-typography-title
                >
            </a-col>
            <a-col :span="18">
                <a-table
                    :columns="tableColumns"
                    :dataSource="formState.plan_change_hists"
                    :pagination="false"
                    :disabled="isLoading"
                    :locale="{ emptyText: noData }"
                >
                    <template #bodyCell="{ column, record }">
                        <template v-if="column.key === 'plan_name'">
                            {{ record.plan?.plan_name }}
                        </template>
                        <template v-if="column.key === 'fee'">
                            ￥{{ formatter.format(record.plan?.fee) }}
                        </template>
                        <template
                            v-if="column.key === 'parallel_publish_item_number'"
                        >
                            {{ record.plan?.parallel_publish_item_number ?? 'なし' }}
                        </template>
                        <template v-if="column.key === 'created_at'">
                            {{
                                record.created_at
                                    ? dayjs(record.created_at).format(
                                          'YYYY-MM-DD'
                                      )
                                    : ''
                            }}
                        </template>
                    </template>
                </a-table>
            </a-col>
        </a-row>
        <a-row type="flex" justify="center" align="center" class="my-8">
            <a-col>
                <a-button
                    size="large"
                    :disabled="isLoading"
                    @click="router.push('/advertisers')"
                >
                    キャンセル
                </a-button>
            </a-col>
            <a-col class="ml-[2%]">
                <a-button
                    type="primary"
                    :disabled="isLoading"
                    size="large"
                    :loading="isLoading"
                    @click="update"
                >
                    <template #icon>
                        <SyncOutlined class="!align-baseline" />
                    </template>
                    保存
                </a-button>
            </a-col>
            <a-col v-if="formState.status_id === 2" class="ml-[2%]">
                <a-button
                    type="primary"
                    :disabled="isLoading || disabledCreateAccount"
                    :loading="isLoading"
                    size="large"
                    @click="createAccount"
                >
                    承認とアカウント発行
                </a-button>
            </a-col>
        </a-row>
    </a-form>
    <a-modal
        v-model:open="changeStatusModal"
        :ok-text="`${changeStatusToLabel}にする`"
        cancel-text="キャンセル"
        :ok-button-props="{ danger: true }"
        @ok="handleChangeStatus"
        class="modal-show"
    >
        <template #title>
            <div class="!flex items-center mb-3">
                <ExclamationCircleOutlined class="!text-[#CD1225] mr-2 text-[25px]" />
                <a-typography-title :level="4" class="!mb-0"
                    >ステータスを{{ changeStatusToLabel }}にしますか？</a-typography-title
                >
            </div>
        </template>
        <a-typography-text class="px-8"
            >{{ `本当に${formState.company_name}様のステータスを${changeStatusToLabel}にしますか？`  }}</a-typography-text
        >
    </a-modal>
</template>

<script setup>
import { computed, onMounted, ref, inject } from 'vue';
import { useApi } from '@/composables/useApi';
import { useMaster } from '@/composables/useMaster';
import { notification } from 'ant-design-vue';
import { useRoute } from 'vue-router';
import router from '@/routers';
import { SyncOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue';

const dayjs = inject('dayjs');

const STATUS_IN_USE = 3;
const STATUS_IN_SUSPENSION = 4;
const STATUS_NEW = 2;

const api = useApi();
const route = useRoute();
const masterStore = useMaster();

const formState = ref({
    status_id: '',
    company_name: '',
    contact_person_name: '',
    contact_person_job_title: '',
    contact_person_phone_number: '',
    contact_person_mail_address: '',
    postcode: '',
    prefecture_id: '',
    address_1: '',
    address_2: '',
    address_3: '',
    display_name: '',
    display_mail_address: '',
    display_phone_number: '',
    display_description: '',
    url: '',
    plan_new: {},
    admin_user: {},
    plan_change_hists: [],
});

const plan_id = ref(0);
const start_date = ref();

const tableColumns = ref([
    {
        title: 'プラン',
        dataIndex: 'plan_name',
        key: 'plan_name',
    },
    {
        title: '金額',
        dataIndex: 'fee',
        key: 'fee',
    },
    {
        title: '並行掲載上限数',
        dataIndex: 'parallel_publish_item_number',
        key: 'parallel_publish_item_number',
    },
    {
        title: '開始日',
        dataIndex: 'start_date',
        key: 'start_date',
    },
    {
        title: '登録日',
        dataIndex: 'created_at',
        key: 'created_at',
    },
]);

const status = [
    {
        key: STATUS_NEW,
        value: '新規申込',
        color: 'green',
    },
    {
        key: STATUS_IN_USE,
        value: '利用中',
        color: 'processing',
    },
    {
        key: STATUS_IN_SUSPENSION,
        value: '利用休止',
        color: 'default',
    },
];

const isLoading = ref(false);
const errorResponse = ref([]);
const dateFormat = ref('YYYY/MM/DD');
const advertiserId = ref();
const statusChangePlan = ref(false);
const noData = 'データがありません';
const imageUrl = ref('');
const fileList = ref([]);
const loading = ref(false);
const domainStorage = process.env.VUE_APP_STORAGE_DOMAIN;
const uploadUrl = process.env.VUE_APP_ADMIN_DOMAIN + '/api/files/upload';
const currentStatus = ref({});

const openChangeStatusModal = () => {
  changeStatusModal.value = true
}

const formatter = new Intl.NumberFormat('ja-JP', {
    maximumFractionDigits: 2,
});

const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
};

async function getAdvertiser() {
    try {
        const response = await api.auth.get(
            `/api/advertisers/` + advertiserId.value
        );

        if (!response?.data?.plan_new && response?.data?.plan_future) {
            response.data.plan_new = response.data.plan_future;
        }
        if (response?.data?.plan_new) {
            response.data.plan_new.start_date = dayjs(
                response?.data?.plan_new?.start_date
            ).format('YYYY-MM-DD');
        } else {
            response.data.plan_new = {};
        }
        if (!response?.data?.admin_user) {
            response.data.admin_user = {};
        }
        if (!response?.data?.plan_change_hists) {
            response.data.plan_change_hists = [];
        }
        currentStatus.value = status.find(s => s.key === response.data.status_id);
        formState.value = response.data;
        imageUrl.value = response.data.avatar;
        plan_id.value = response.data.plan_new?.plan_id;
        start_date.value = response.data.plan_new?.start_date;
    } catch (error) {
        console.error(error);
    }
}

const tryGetErrorResponse = (error) => {
    try {
        errorResponse.value = error.response.data.errors;
        return true;
    } catch {
        return false;
    }
};

const disabledCreateAccount = computed(() => {
    return !(
        formState.value?.plan_new?.plan_id &&
        formState.value?.admin_user?.name &&
        formState.value?.admin_user?.mail_address
    );
});

const isInvalid = (field) => {
    return errorResponse.value?.[field];
};

const invalidMessages = (field) => {
    return errorResponse.value?.[field] || [];
};

const showNotification = (type, message, description) => {
    notification[type]({
        message,
        description: description ? description : '',
    });
};

const handleChange = (info) => {
    imageUrl.value = '';
    if (!info.file.status) {
        fileList.value = [];
    }
    if (info.file.status === 'uploading') {
        loading.value = true;
        return;
    }
    if (info.file.status === 'done') {
        imageUrl.value = domainStorage + info?.file?.response?.path
        loading.value = false;
    }
    if (info.file.status === 'error') {
        imageUrl.value = '';
        fileList.value = [];
        loading.value = false;
        showNotification(
            'error',
            'ファイルをアップロードできませんでした'
        );
    }
};

function uploadImage(file) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    const validSize = file.size < 100 * 1024 * 1024;
    if (!validSize) {
        showNotification(
            'error',
            'ファイルサイズが大きすぎます',
            '1ファイルあたりの最大アップロードサイズは100MBです。'
        );
    } else if (!isJPG) {
        showNotification(
            'error',
            'ファイルの形式が正しくありません',
            '対応するファイル形式はJPG/PNGです。'
        );
    }
    return validSize && isJPG;
}

async function getAddressByPostcode() {
    isLoading.value = true;
    try {
        const response = await api.withoutAuth.get(
            `/api/search-zipcode?zipcode=${formState.value.postcode}`
        );
        formState.value.prefecture_id = parseInt(
            response.data.results[0].prefcode
        );
        formState.value.address_1 = response.data.results[0].address2;
        formState.value.address_2 = response.data.results[0].address3;
    } catch (error) {
        console.error(error);
        showNotification(
            'error',
            '住所が見つかりませんでした',
            '入力された郵便番号に該当する住所が見つかりませんでした。入力内容を確認してください。'
        );
    }
    isLoading.value = false;
}

const update = async () => {
    isLoading.value = true;
    errorResponse.value = [];
    try {
        formState.value.plan_id = formState.value?.plan_new?.plan_id;
        formState.value.start_date = formState.value?.plan_new?.start_date;
        formState.value.avatar = imageUrl.value;
        await api.auth.put(
            `/api/advertisers/` + advertiserId.value,
            formState.value
        );
        await getAdvertiser();
        statusChangePlan.value = false;
        showNotification('success', '入力内容が保存されました');
    } catch (error) {
        if (error?.response?.status === 422) {
            tryGetErrorResponse(error);
            showNotification(
                'error',
                'エラーが発生しました',
                '入力内容に不備があるため、保存できませんでした。項目の下に表示されているメッセージを確認してください。'
            );
        } else {
            showNotification(
                'error',
                'エラーが発生しました',
                '入力内容の保存中にエラーが発生しました。しばらくしてからもう一度お試しください。'
            );
        }
    }
    isLoading.value = false;
};

const createAccount = async () => {
    isLoading.value = true;
    errorResponse.value = [];
    try {
        await api.auth.post(`/api/advertiser/create_auth0`, {
            advertiser_id: advertiserId.value,
            contact_person_mail_address:
                formState.value.contact_person_mail_address,
            mail_address: formState.value?.admin_user?.mail_address,
            name: formState.value?.admin_user?.name,
            plan_id: formState.value?.plan_new?.plan_id,
            start_date: formState.value?.plan_new?.start_date,
        });
        await getAdvertiser();
        statusChangePlan.value = true;
        showNotification('success', '承認とアカウント発行が完了しました');
    } catch (error) {
        console.error(error);
        if (error?.response?.status === 422) {
            tryGetErrorResponse(error);
            showNotification(
                'error',
                'エラーが発生しました',
                '入力内容に不備があるため、保存できませんでした。項目の下に表示されているメッセージを確認してください。'
            );
        } else if (error?.response?.status === 400) {
            showNotification(
                'error',
                'エラーが発生しました',
                error?.response.data.message
            );
        } else {
            showNotification(
                'error',
                'エラーが発生しました',
                '入力内容の保存中にエラーが発生しました。しばらくしてからもう一度お試しください。'
            );
        }
    }
    isLoading.value = false;
};

function handleChangePlan(value) {
    statusChangePlan.value = true;
    if (value === plan_id.value) {
        statusChangePlan.value = false;
        formState.value.plan_new.start_date = start_date;
    }
}

const changeStatusModal = ref(false);
const changeStatusToLabel = computed(() => {
  if (!currentStatus.value?.key) {
    return ''
  }
  if (currentStatus.value.key === STATUS_IN_SUSPENSION) { // 現在利用休止中
    return status.find(s =>s.key === STATUS_IN_USE).value;
  }
  return status.find(s =>s.key === STATUS_IN_SUSPENSION).value;
})

const labelCol = {
    style: {
        width: '250px',
        textAlign: 'left',
    },
};

const wrapperCol = {
    style: {
        minWidth: '400px',
        flexGrow: 0,
    },
};


const planMessage = computed(() => {
  if (formState.value.plan_change_hists.length > 0) {
    const futurePlan = formState.value.plan_change_hists.find(h => dayjs(h.start_date).isAfter(dayjs()))
    if (futurePlan) {
      return `${dayjs(futurePlan.start_date).format('YYYY年M月D日')}から、${futurePlan.plan.plan_name}プランへの変更が予定されています。`
    }
    return ''
  }
  return ''
});

onMounted(async () => {
    isLoading.value = true;
    advertiserId.value = route.params.id;
    await getAdvertiser();
    isLoading.value = false;
});

const handleChangeStatus = async() => {
  let nextStatus;
  if (currentStatus.value.key === STATUS_IN_SUSPENSION) {
    nextStatus = status.find(s =>s.key === STATUS_IN_USE).key;
  } else {
    nextStatus = status.find(s =>s.key === STATUS_IN_SUSPENSION).key;
  }
  formState.value.status_id = nextStatus;
  changeStatusModal.value = false;
  await update();
}
</script>
<style scoped>
.spin-custom .ant-spin {
    position: fixed !important;
    top: 50% !important;
    transform: translateY(-50%) translateX(50%);
    width: 100px !important;
    left: 50% !important;
}

.ant-table {
    border-radius: 4px !important;
}

.ant-table-container {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}

.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
    border-radius: 50% !important;
}
</style>
