import ItemList from "../views/item/ListItem.vue";
import ItemCreate from "@/views/item/Create.vue";
import EditItem from "@/views/item/Edit.vue";
import IsAdvertiser from "@/middlewares/isAdvertiser"
export const ItemRoutes = [
    {
        path: "/items",
        name:'item.list',
        component:ItemList,
        meta: {
            menuKey: "item",
            breadcrumbTitle: "PR商品一覧",
        },
    },
    {
        path: "/items/create",
        name: "item.create",
        component: ItemCreate,
        meta: {
            menuKey: "item",
            breadcrumbTitle: "PR商品登録",
            middleware: IsAdvertiser,
        },
    },
    {
        path: "/items/:id/v/:version",
        name:'item.edit',
        component:EditItem,
        meta: {
            menuKey: "item",
            breadcrumbTitle: "PR商品編集",
        },
    },
];

