<template>
    <div class="max-w-6xl mx-auto">
        <div class="pt-8 px-[120px]" v-if="this.$route.name !== 'register'">
            <div class="w-24">
                <img src="./assets/logo.svg" alt="logo" />
            </div>
            <div class="mt-16">
                <a-typography-title :level="4" class="text-center !mb-8">{{
                    this.$route.meta.title
                }}</a-typography-title>
                <a-layout
                    :style="{
                        padding: '24px',
                        margin: '0 auto',
                        maxWidth: '500px',
                        minHeight: 'auto',
                    }"
                >
                    <router-view />
                </a-layout>
            </div>
        </div>
        <div class="pt-[30px]" v-else>
            <div class="w-full flex justify-center">
                <div class="h-[64px] w-auto">
                    <img src="./assets/logo.svg" alt="logo" class="h-[64px]"/>
                </div>
            </div>
            <div class="mt-[40px]">
                <a-typography-title :level="4" class="text-center md:!mb-[60px] !mb-[20px] !text-[26px] md:!text-[32px] !text-[#000000] md:block hidden">ぐるかい 広告アカウント登録</a-typography-title>
                <a-typography-title :level="4" class="text-center md:!mb-[60px] !mb-[20px] !text-[26px] md:!text-[32px] !text-[#000000] md:hidden block">ぐるかい <br> 広告アカウント登録</a-typography-title>
                <div class="step mx-3 md:!block !hidden">
                    <a-steps :current="4" :responsive="false">
                        <template #progressDot="{ index }">
                            <a-typography-text class="!w-[60px] !h-[60px] !block leading-[60px] rounded-full bg-[#D2D2D2] text-[34px] font-bold	!text-[#333333] translate-y-[-26px] translate-x-[-26px]" :class="index === 0 ? '!bg-[#CD1225] !text-[#ffffff]' : ''" > {{ index + 1 }}</a-typography-text>
                        </template>
                        <a-step>
                            <template #description>
                                <a-typography-text class="!text-[#333333] !text-[16px]">新規申込 <br> 必要事項入力</a-typography-text>
                            </template>
                            <template #title>
                                <a-typography-text class="!text-[#333333] font-bold">[申込者様]</a-typography-text>
                            </template>
                        </a-step>

                        <a-step>
                            <template #description>
                                <a-typography-text class="!text-[#333333] !text-[16px]">ご案内メール受信 <br> 認証URLをクリック</a-typography-text>
                            </template>
                            <template #title>
                                <a-typography-text class="!text-[#333333] font-bold">[申込者様]</a-typography-text>
                            </template>
                        </a-step>
                        <a-step>
                            <template #description>
                                <a-typography-text class="!text-[#333333] !text-[16px]">申込者様にご連絡 <br> ヒアリングと審査</a-typography-text>
                            </template>
                            <template #title>
                                <a-typography-text class="!text-[#333333] font-bold">[ぐるかい運営]</a-typography-text>
                            </template>
                        </a-step>
                        <a-step>
                            <template #description>
                                <a-typography-text class="!text-[#333333] !text-[16px]">アカウント発行 <br> 申込者様にご連絡</a-typography-text>
                            </template>
                            <template #title>
                                <a-typography-text class="!text-[#333333] font-bold">[ぐるかい運営]</a-typography-text>
                            </template>
                        </a-step>
                        <a-step>
                            <template #description>
                                <a-typography-text class="!text-[#333333] !text-[16px]">ログインして <br> 利用開始</a-typography-text>
                            </template>
                            <template #title>
                                <a-typography-text class="!text-[#333333] font-bold">[申込者様]</a-typography-text>
                            </template>
                        </a-step>
                    </a-steps>
                </div>

                <div class="min-step md:!hidden !flex flex-wrap row justify-center mx-3 ">
                    <div class="flex flex-col p-[6px] items-center flex-[0_0_33%] relative step-item">
                        <div class="number !w-[60px] !h-[60px] !block leading-[60px] rounded-full text-[34px] font-bold !bg-[#CD1225] !text-[#ffffff] text-center" >1</div>
                        <div class="title text-[#333333] font-bold">[申込者様]</div>
                        <div class="description text-[#333333] text-center">新規申込 <br> 必要事項入力</div>
                    </div>
                    <div class="flex flex-col p-[6px] items-center flex-[0_0_33%] relative step-item">
                        <div class="number !w-[60px] !h-[60px] !block leading-[60px] rounded-full bg-[#D2D2D2] text-[34px] font-bold text-[#333333] text-center">2</div>
                        <div class="title text-[#333333] font-bold">[申込者様]</div>
                        <div class="description text-[#333333] text-center">ご案内メール受信 <br> 認証URLをクリック</div>
                    </div>
                    <div class="flex flex-col p-[6px] items-center flex-[0_0_33%] relative step-item">
                        <div class="number !w-[60px] !h-[60px] !block leading-[60px] rounded-full bg-[#D2D2D2] text-[34px] font-bold text-[#333333] text-center">3</div>
                        <div class="title text-[#333333] font-bold">[ぐるかい運営]</div>
                        <div class="description text-[#333333] text-center">申込者様にご連絡 <br> ヒアリングと審査</div>
                    </div>
                    <div class="flex flex-col p-[6px] items-center flex-[0_0_33%] relative step-item">
                        <div class="number !w-[60px] !h-[60px] !block leading-[60px] rounded-full bg-[#D2D2D2] text-[34px] font-bold text-[#333333] text-center">4</div>
                        <div class="title text-[#333333] font-bold">[ぐるかい運営]</div>
                        <div class="description text-[#333333] text-center">アカウント発行 <br> 申込者様にご連絡</div>
                    </div>
                    <div class="flex flex-col p-[6px] items-center flex-[0_0_33%] relative step-item">
                        <div class="number !w-[60px] !h-[60px] !block leading-[60px] rounded-full bg-[#D2D2D2] text-[34px] font-bold text-[#333333] text-center">5</div>
                        <div class="title text-[#333333] font-bold">[申込者様]</div>
                        <div class="description text-[#333333] text-center">ログインして <br> 利用開始</div>
                    </div></div>
                <a-layout class="!bg-white mt-5">
                    <router-view />
                </a-layout>
            </div>
        </div>
    </div>
</template>

<script setup>
</script>

<style>
.step .ant-steps-label-vertical .ant-steps-item-content {
    margin-top: 35px;
}
.step .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #D2D2D2;
    height: 4px;
}

.min-step .number::after {
    content: '';
    border: 2px solid #D2D2D2;
    height: 4px;
    width: calc(50% - 30px);
    position: absolute;
    top: 36px;
    right: 0;
}

.min-step .number::before {
    content: '';
    border: 2px solid #D2D2D2;
    height: 4px;
    width: calc(50% - 30px);
    position: absolute;
    top: 36px;
    left: 0;
}

.min-step .step-item:nth-child(1) .number::before {
    content: unset;
}

.min-step .step-item:nth-child(5) .number::after {
    content: unset;
}

.min-step .step-item:nth-child(3) .number::after, .min-step .step-item:nth-child(4) .number::before {
    border-style: dashed;
}

.step .ant-steps-dot .ant-steps-item-content, .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    min-width: 145px;
}

</style>
