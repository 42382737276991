<template>
    <div id="app"></div>
    <a-config-provider
        :theme="{
          token: {
            colorPrimary: '#21798D',
            colorError: '#CD1225',
            colorLink: '#000',
            colorLinkHover: 'rgba(0, 0, 0, .8)',
            colorActive: '#000',
            colorInfo: '#21798D',
            colorInfoBg: '#E7FBFF',
            colorInfoBorder: '#B5EAFF',
          },
        }"
      >
        <router-view/>
    </a-config-provider>
</template>
<script setup>
</script>

<style scoped>
</style>
