import AdvertiserList from "@/views/advertiser/AdvertiserList.vue";
import AdvertiserProfile from "@/views/advertiser/AdvertiserProfile.vue";
import AdvertiserEdit from '../views/advertiser/AdvertiserEdit.vue';
export const AdvertiserRoutes = [
    {
        path: '/profile',
        name: 'profile',
        component: AdvertiserProfile,
        meta: {
            menuKey: 'profile',
            breadcrumbTitle: '広告主編集',
        },
    },
    {
        path: '/advertisers',
        name: 'advertisers',
        component: AdvertiserList,
        meta: {
            menuKey: 'advertisers',
            breadcrumbTitle: '広告主一覧',
        },
    },
    {
        path: '/advertiser/:id',
        name: 'advertisers.edit',
        component: AdvertiserEdit,
        meta: {
            menuKey: 'advertisers',
            breadcrumbTitle: '広告主編集',
        },
    },
];
