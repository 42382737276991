<template>
  <a-form :model="formState" autocomplete="off">
      <a-row class="mb-8">
          <a-col :span="6">
              <a-typography-title :level="4" class="p-2"
                  >設定</a-typography-title
              >
          </a-col>
          <a-col :span="18">
              <a-card :bordered="false">
                  <a-form-item
                      label="チュートリアルURL"
                      :colon="false"
                      required
                      :label-col="labelCol"
                  >
                      <a-input
                          v-model:value="formState.tutorial_url"
                          :disabled="isLoading"
                          size="large"
                      />
                  </a-form-item>
              </a-card>
          </a-col>
      </a-row>
      <a-row type="flex" justify="center" align="center" class="my-8">
          <a-col>
              <a-button
                  size="large"
                  :disabled="isLoading"
                  @click="router.push({name: 'admin.approval'});"
              >
                  キャンセル
              </a-button>
          </a-col>
          <a-col class="ml-[2%]">
              <a-button
                  type="primary"
                  :disabled="isLoading"
                  size="large"
                  :loading="isLoading"
                  @click="update"
              >
                  <template #icon>
                      <SyncOutlined class="!align-baseline" />
                  </template>
                  保存
              </a-button>
          </a-col>
      </a-row>
  </a-form>
</template>
<script setup>
import { SyncOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
// import { useApi } from '@/composables/useApi';
// import { notification } from 'ant-design-vue';
import { useRouter } from 'vue-router';

const router = useRouter();
// const api = useApi();
const isLoading = ref(false);
// const errorResponse = ref([]);
const formState = ref({
  tutorial_url: ''
});

// onMounted(() => {
//   isLoading.value = true;
//   isLoading.value = false;
// });

const labelCol = {
  style: {
      width: '250px',
      textAlign: 'left',
  },
};

// const isInvalid = (field) => {
//     return errorResponse.value?.[field];
// };

// const invalidMessages = (field) => {
//     return errorResponse.value?.[field] || [];
// };

const update = () => {
  //
}
</script>
