import {createRouter, createWebHistory} from "vue-router";
import { useAuth } from "@/composables/useAuth";
import LayoutAdmin from "@/LayoutAdmin.vue";
import Dashboard from "@/views/home/Dashboard.vue";
import {AuthRoutes} from "@/routers/AuthRoutes";
import {ItemRoutes} from "@/routers/ItemRoutes";
import {AdvertiserRoutes} from "@/routers/AdvertiserRoutes";
import auth from "@/middlewares/auth";
import { AdminRoutes } from './AdminRoutes';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: LayoutAdmin,
            meta: {
                middleware: auth,
            },
            children: [
                {
                    path: "/",
                    name: 'home',
                    component: Dashboard,
                    meta: {
                        menuKey: 'home',
                        breadcrumbTitle: '',
                    }
                },
                ...ItemRoutes,
                ...AdvertiserRoutes,
                ...AdminRoutes
            ],
        },
        ...AuthRoutes,
    ],
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

router.afterEach((to, from) => {
    if ((!from.name || from.name == 'login') && to.name == 'home') {
        const {authUser} = useAuth()
        if (authUser.is_admin) {
            router.push({name: 'admin.approval'});
        }
        if (authUser.is_advertiser) {
            router.push({name: 'item.list'});
        }
    }
});

export default router;
