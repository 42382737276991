import axios from 'axios';
import { defineStore } from 'pinia';
import { useAuth } from './useAuth';
import { ref } from 'vue';

export const useApi = defineStore('api', () => {
  const { token } = useAuth();

  const auth = ref(
    axios.create({
      baseURL: process.env.VUE_APP_ADMIN_DOMAIN,
      headers: {
        Authorization: 'Bearer ' + (token || localStorage.getItem('access_token'))
      }
    })
  );

  const withoutAuth = ref(
    axios.create({
      baseURL: process.env.VUE_APP_ADMIN_DOMAIN
    })
  );

  return { auth, withoutAuth };
});


/*
How to use
import { useApi } from "@/composables/useApi"

const api = useApi()

// Call API with auth
api.auth.get('/path')
api.auth.post('/path')

// Call API without auth
api.withoutAuth.get('/path')
api.withoutAuth.post('/path')
*/
