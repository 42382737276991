<template>
    <a-layout-header :style="headerStyle">
        <menu-unfold-outlined
            v-if="collapsed"
            :style="{ color: '#666666' }"
            @click="toggleCollapsed"
            class="trigger collapsedIcon"
        />
        <menu-fold-outlined
            v-else
            :style="{ color: '#666666' }"
            @click="toggleCollapsed"
            class="trigger collapsedIcon"
        />
        <a-typography-text class="header-title" strong>{{route.meta.breadcrumbTitle}}</a-typography-text>

        <div class="ml-auto mr-14">
            <a-dropdown v-if="authUser.is_advertiser" class="cursor-pointer">
                <div class="text-[#666]">
                    <a-avatar v-if="authUser.advertiser?.avatar" :src="authUser.advertiser.avatar" class="mr-2 border border-[#b5b5b5]" />
                    <span>{{ authUser?.advertiser?.company_name}}</span>
                    <a-divider style="height: 15px; background-color: #d9d9d9" type="vertical" />
                    <span>{{ authUser.name }}</span>
                </div>
                <template #overlay >
                    <a-menu>
                        <a-menu-item>
                            <a-button @click="router.push({ name: 'profile'})" type="text">広告主情報</a-button>
                        </a-menu-item>
                    </a-menu>
                </template>
            </a-dropdown>
            <div v-else class="text-[#666]">
                <span>管理者</span>
                <a-divider style="height: 15px; background-color: #d9d9d9" type="vertical" />
                <span>{{ authUser.name }}</span>
            </div>
        </div>
    </a-layout-header>
    <Teleport to="body">
        <a-modal
            v-model:open="isSuspendedAdvertiser"
            class="modal-show"
            :title="null"
            :closable="false"
            :footer="null"
            :mask-closable="false"
        >
        <div class="text-center text-lg">{{ `${authUser?.advertiser?.company_name} 様`  }}</div>
            <div class="py-5">
              <p>このアカウントは管理者によって利用休止とされています。</p>
              <p>お問い合わせは（<a class="underline" href="mailto:info@gurukai.co.jp">info@gurukai.co.jp</a>）までお願いします。</p>
            </div>
        </a-modal>
    </Teleport>
</template>
<script setup>
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons-vue';
import { defineProps, onMounted, ref, computed, watchEffect } from 'vue';
import { useAuth } from '@/composables/useAuth';
import { useRoute, useRouter } from 'vue-router';
const authUser = ref({});
const route = useRoute()
const router = useRouter()
const props = defineProps({
    collapsed: Boolean,
    toggleCollapsed: Function,
});
const isSuspendedAdvertiser = ref(false);
onMounted(async () => {
    const authStore = useAuth();
    authUser.value = authStore.authUser;
});

watchEffect(() => {
  if (authUser.value?.advertiser?.status_id === 4) {
    isSuspendedAdvertiser.value = true;
  } else {
    isSuspendedAdvertiser.value = false;
  }
})

const headerStyle = computed(() => {
    const siderWidth = props.collapsed ? '80px' : '200px';
    return {
        background: '#fff',
        padding: 0,
        position: 'fixed',
        width: `calc(100% - ${siderWidth})`,
        display: 'flex',
        alignItems: 'center'
    }
})

</script>
<style scoped>
.collapsedIcon {
    position: absolute;
    font-size: 24px;
    top: 30%;
    padding-left: 26px;
}
.header-title {
    padding-left: 80px;
    font-size: 24px
}

</style>

<style>
#app .custom-table-thead .ant-table-thead .ant-table-cell {
    font-weight: 700;
}
</style>
