<template>
    <div>
        ようこそぐるかい管理画面へ！
    </div>
</template>

<script>
export default {
    name: 'DashboardForm',
};
</script>
