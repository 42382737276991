import {useAuth} from "@/composables/useAuth";
import { useMaster } from "@/composables/useMaster";

export default async function auth({ next, router}) {
    const auth = useAuth()
    const masterStore = useMaster()

    if (!auth.authUser?.id) {
      await auth.fetchUser();
      await masterStore.getMasterData();
    }

    if (!auth.authUser?.is_advertiser) {
        return router.push({ name: 'item.list' })
    }

    return next();
}
