import { ref } from "vue";
import { defineStore } from "pinia";
import { useApi } from '@/composables/useApi'

export const useAuth = defineStore('auth', () => {
    const api = useApi();

    const authUser = ref({})
    const isLoggedIn = ref(false)
    const token = ref(localStorage.getItem('access_token') || null)
    const message = ref()
    const collapsedState = ref({
        collapsed: false,
    })
    const total = ref(0)

    async function fetchUser() {
        if (!token.value) return

        try {
            const response = await api.auth.get('api/user')
            authUser.value = response.data;
            isLoggedIn.value = true;
        } catch (error) {
            console.log(error)
        }
    }

    async function logout() {
        isLoggedIn.value = false;
        token.value = null;
        window.location.href = process.env.VUE_APP_URL_LOGOUT;
        localStorage.removeItem('access_token');
    }

    function toggleCollapsed()  {
        collapsedState.value.collapsed = !collapsedState.value.collapsed;
    }

    function setCollapsed(value) {
        collapsedState.value.collapsed = value
    }

    async function getTotalMessage() {
        try {
            const config = {
                params: {
                    filter: 0,
                }
            };
            const response = await api.auth.get('/api/approve-list', config);

            total.value = response.data.data.length;
        } catch (error) {
            total.value = 0;
            console.log(error);
        }
    }

    const setTotal = (value) => {
      total.value = value;
    }

    return { authUser, isLoggedIn, token, message, collapsedState, fetchUser, logout, toggleCollapsed, setCollapsed, total, getTotalMessage, setTotal }
});
