<template>
<div>
    <video ref="videoPlayer" class="video-js" controls preload="auto" width="640" height="264"></video>
</div>
</template>

<script setup>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import {onBeforeUnmount, onMounted, ref, defineProps} from "vue";

const props = defineProps(['options']);
const videoPlayer = ref(null);
let player = null;

onMounted(() => {
    player = videojs(videoPlayer.value, props.options, function onPlayerReady() {
        console.log('onPlayerReady', this);
    });
});

onBeforeUnmount(() => {
    if (player) {
        player.dispose();
    }
});
</script>
