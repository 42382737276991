<template>
    <a-layout-content :style="{ backgroundColor: '#fff', padding: '20px 24px',  marginBottom: '20px' }">
        <a-row align="middle" class="mb-4">
            <FilterOutlined />
            <a-col class="ml-3">
                <a-radio-group
                    v-model:value="filter"
                    button-style="solid"
                >
                    <a-radio-button
                        v-for="item in type"
                        :key="item.value"
                        :value="item.value"
                        :class="item.color"
                        @focus="selectFilter(item.value)"
                    >
                        {{ item.text }}
                    </a-radio-button>
                </a-radio-group>
            </a-col>
        </a-row>
        <a-table
            :loading="isLoading"
            :columns="columns"
            :data-source="approveList"
            :pagination="false"
            :locale="{ emptyText: noData }"
            :custom-row="((record) => {
              return {
                onClick: () => move(record),
            }})"
        >
            <template #bodyCell="{ column, text, record }">
                <template v-if="column.key === 'action'">
                    <div
                        v-if="record.type != 'new_advertiser'"
                        class="cursor-pointer"
                        @click="router.push({ name:'item.edit', params: { id: record.id, version: record.version_id} })"
                    >
                        <EditOutlined :style="{ color: '#666' }" />
                    </div>
                    <div
                        v-else
                        class="cursor-pointer"
                        @click="router.push({ name:'advertisers.edit', params: { id: record.id } })"
                    >
                        <EditOutlined :style="{ color: '#666' }" />
                    </div>
                </template>
                <template v-if="column.key === 'company_name'">
                    <a-typography-link
                        class="action"
                        underline
                        @click="router.push({ name:'advertisers.edit', params: { id: record.type === 'new_advertiser' ? record.id : record.advertiser_id } })">
                        {{ record?.advertiser?.company_name ? record?.advertiser?.company_name : record?.company_name }}
                    </a-typography-link>
                </template>
                <template v-if="column.key === 'name'">
                    <div
                        v-if="record.type != 'new_advertiser'"
                        @click="router.push({ name:'item.edit', params: { id: record.id, version: record.version_id} })"
                    >
                        {{ record?.name ?? '-' }}
                    </div>
                    <div
                        v-else
                    >
                        {{ record?.name ?? '-' }}
                    </div>
                </template>
                <template v-if="column.key === 'type'">
                    <a-tag
                        :color="text === 'new_advertiser'
                                ? 'green'
                                : text === 'new_item'
                                ? 'processing'
                                : 'default'
                        "
                        class="!mr-0"
                    >
                        {{ displayType(text) }}
                    </a-tag>
                </template>
            </template>
        </a-table>
    </a-layout-content>
</template>

<script>
export default {
    name: 'ApprovalList',
}
</script>

<script setup>
import {useApi} from "@/composables/useApi";
import {onMounted, ref} from "vue";
import {useAuth} from "@/composables/useAuth";
import {useRouter} from "vue-router";
import { EditOutlined, FilterOutlined } from "@ant-design/icons-vue";
const router = useRouter()

const columns = [
    {
        title: '種別',
        dataIndex: 'type',
        key: 'type',
        width: 170
    },
    {
        title: '会社名',
        dataIndex: 'company_name',
        key: 'company_name',
        width: 250
    },
    {
        title: '商品名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '申請日',
        dataIndex: 'date_created_at',
        key: 'date_created_at',
        width: 120
    },
    {
        title: '編集',
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 80
    },
];

const type = [
    {
        value: '0',
        text: '全て',
        color: 'grey'
    },
    {
        value: '1',
        text: '新規広告主',
        color: 'green'
    },
    {
        value: '2',
        text: 'PR新規登録',
        color: 'blue'
    },
    {
        value: '3',
        text: 'PR商品更新',
        color: 'orange'
    }
];


const api = useApi();
const { setTotal } = useAuth();
const approveList = ref([]);
const isLoading = ref(false);
const filter = ref('0');
const noData = "承認待ちのデータがありません";

const getApproveList = async () => {
    try {
        isLoading.value = true;
        const config = {
            params: {
                filter: filter.value,
            }
        };
        const response = await api.auth.get('/api/approve-list', config);
        approveList.value = response.data.data;
        setTotal(approveList.value.length)
    } catch (error) {
        console.log(error);
    } finally {
        isLoading.value = false;
    }
}

const selectFilter = async (type) => {
    filter.value = type;
    await getApproveList();
}

const displayType = (type) => {
    if (type === 'new_advertiser') {
        return '新規広告主';
    }
    if (type === 'new_item') {
        return 'PR新規登録';
    }
    if (type === 'update_item') {
        return 'PR商品更新';
    }
}

onMounted(async () => {
    await getApproveList();
});

const move = (record) => {
  if (record.type === 'new_advertiser') {
    router.push('/advertiser/' + record.id)
  } else {
    router.push({ name:'item.edit', params: { id: record.id, version: record.version_id} })
  }
}
</script>
