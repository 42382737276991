// app.js
import './sass/app.scss'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import routers from "./routers";
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import Antd from 'ant-design-vue';
import './assets/tailwind.css';
import 'ant-design-vue/dist/reset.css';
import 'axios';

dayjs.locale('ja')
dayjs.extend(require('dayjs/plugin/isSameOrBefore'))


const pina = createPinia()
const app = createApp(App)

app.use(pina)
app.use(routers)
app.use(Antd)
app.provide('dayjs', dayjs)
app.mount('#app')
