<template>
    <a-layout-content :style="{ backgroundColor: '#fff', padding: '20px 24px',  marginBottom: '20px', borderRadius: '2px' }">
        <a-row :gutter="12" align="middle" class="mb-4">
            <a-col>
                <a-input
                    v-model:value="searchParams.q_fee_text"
                    style="width: 300px"
                    :disabled="isLoading"
                    v-on:keyup.enter="search"
                    v-on:blur="search"
                >
                    <template #prefix>
                        <SearchOutlined />
                    </template>
                </a-input>
            </a-col>
            <a-col>
                <a-divider style="height: 15px; background-color: #d9d9d9" type="vertical" />
            </a-col>
            <FilterOutlined />
            <a-col class="ml-2">
                <a-radio-group
                    v-model:value="searchParams.q_status"
                    button-style="solid"
                    :disabled="isLoading"
                    :autofocus="false"
                >
                    <a-radio-button
                        v-for="item in optionsStatus"
                        :key="item.value"
                        :value="item.value"
                        @focus="searchStatus(item.value)"
                    >
                        {{ item.text }}
                    </a-radio-button>
                </a-radio-group>
            </a-col>
            <a-col v-if="!authUser.is_advertiser" class="ml-auto">
                <a-typography-text class="mr-3">広告主</a-typography-text>
                <a-select
                    placeholder="広告主"
                    v-model:value="searchParams.q_advertiser_id"
                    :disabled="isLoading"
                    :filter-option="filterOption"
                    show-search
                    style="width: 240px"
                    :notFoundContent="noData"
                    @change="search"
                >
                    <a-select-option key="0" value="">
                        全て
                    </a-select-option>
                    <a-select-option
                        v-for="item in optionsAdvertiser"
                        :key="`company_id${item.id}`"
                        :value="item.id"
                        :name="item.company_name"
                    >
                        {{ item.company_name }}
                    </a-select-option>
                </a-select>
            </a-col>
            <a-col v-else class="ml-auto">
              <a-button
                  type="primary"
                  :disabled="isLoading"
                  @click="router.push({ name: 'item.create'})"
              >
                  <template #icon>
                      <PlusCircleOutlined :style="{ verticalAlign: 'middle', marginTop: '-3px' }" />
                  </template>
                  新規登録
              </a-button>
            </a-col>
        </a-row>
        <a-table
            :loading="isLoading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="pagination"
            :locale="{ emptyText: noData }"
            :custom-row="((record) => {
              return {
                onClick: () => router.push({ name:'item.edit', params: { id: record.id, version: record.version_id} }),
            }})"
            @change="handleTableChange"
            class="custom-table-thead"
            :row-class-name="'break-text-list-consulting'"
        >
            <template #bodyCell="{column, record}">
                <template v-if="column.key === 'action'">
                    <div class="cursor-pointer" @click="router.push({ name:'item.edit', params: { id: record.id, version: record.version_id} })">
                        <EditOutlined :style="{ color: '#666' }" />
                    </div>
                </template>

                <template v-if="column.key === 'publish'">
                    <a-typography>{{ (record.publish_from ? record.publish_from : "") + " ~ " + (record.publish_to ? record.publish_to : "") }}</a-typography>
                </template>
                <template v-if="column.key === 'price'">
                    <a-typography>{{ record.price ? `￥${record.price.toLocaleString()}` : '' }}</a-typography>
                </template>
                <template v-if="column.key === 'company_name'">
                    <a-typography>
                      {{ record.company_name }}
                    </a-typography>
                </template>
                <template v-if="column.key === 'status'">
                    <a-tag :color="getOptionValue(record?.status_search)?.color"> {{ getOptionValue(record?.status_search)?.text }}</a-tag>
                </template>
            </template>
        </a-table>
      </a-layout-content>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {useAuth} from "@/composables/useAuth";
import { useApi } from "@/composables/useApi"
import {useRouter} from "vue-router";
import { EditOutlined, SearchOutlined, FilterOutlined, PlusCircleOutlined } from "@ant-design/icons-vue";

const router = useRouter()
const { authUser } = useAuth();
authUser.value = authUser;

const currentPage = ref(1);
const pageSize = ref(20);
const isLoading = ref(false);
const dataSource = ref([]);
const pagination = ref({});
const searchParams = ref({
    q_advertiser_id: '',
    q_fee_text: '',
    q_status: '2',
});
const optionsStatus = ref([
    {
        value: '1',
        text: '未公開',
        color: 'default'
    },
    {
        value: '2',
        text: '公開中',
        color: 'processing'
    },
    {
        value: '3',
        text: '終了',
        color: 'default'
    },
    {
        value: '4',
        text: '承認待ち',
        color: 'orange'
    },
    {
        value: '0',
        text: '却下',
        color: 'error'
    }
]);

const adminColumns = [
    {
        title: '商品名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '会社名',
        dataIndex: 'company_name',
        key: 'company_name',
        width: 250
    },
    {
        title: '公開期間',
        dataIndex: 'publish',
        key: 'publish',
        width: 210
    },
    {
        title: 'ステータス',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        align: 'center'
    },
    {
        title: '編集',
        key: 'action',
        width: 80,
        align: 'center'
    },
]

const advertiserColumns = [
    {
        title: '商品名',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: '公開期間',
        dataIndex: 'publish',
        key: 'publish',
        width: 210
    },
    {
        title: '価格',
        dataIndex: 'price',
        key: 'price',
        width: 150
    },
    {
        title: 'ステータス',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        align: 'center'
    },
    {
        title: '編集',
        key: 'action',
        width: 80,
        align: 'center'
    },
];
const tableColumns = ref([]);
if(authUser.is_advertiser) {
  tableColumns.value = advertiserColumns;
} else {
  tableColumns.value = adminColumns;
}
const optionsAdvertiser = ref([]);
const noData = 'データがありません';
const api = useApi();

const filterOption = (input, option) => {
    return String(option.name).toLowerCase().indexOf(String(input).toLowerCase()) >= 0;
};


onMounted(async () => {
    await getItems();
    if (!authUser.is_advertiser) {
        await getAdvertiser();
    }
});

async function handleTableChange(pag) {
    isLoading.value = true;
    currentPage.value = pag.current;
    pageSize.value = pag.pageSize;

    await getItems(searchParams.value);
}

function search() {
    currentPage.value = 1;
    getItems();
}

function searchStatus(status) {
    searchParams.value.q_status = status;
    currentPage.value = 1;
    getItems();
}

async function getItems() {
    isLoading.value = true;
    try {
        const config = {
            params: {...searchParams.value, page: currentPage.value, per_page: pageSize.value},
        };
        const response = await api.auth.get('/api/items', config);
        dataSource.value = response.data.data;
        pagination.value = {
            current: response.data.current_page,
            pageSize: response.data.per_page,
            total: response.data.total,
            showTotal: (total) => `合計: ${total}件`,
            showSizeChanger:false
        };
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
}

async function getAdvertiser() {
    try {
        const response = await api.auth.get('/api/items/advertiser');
        optionsAdvertiser.value = response.data;
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
}
function getOptionValue(key) {
    const optionValue = optionsStatus.value.filter(item => item.value == key);
    return optionValue ? optionValue[0] : {};
}
</script>

<style scoped>
.group-btn-search {
    text-align: end;
}
</style>
