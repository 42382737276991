<template>
    <div class="text-center">
        <a-button type="primary" @click="redirectToLogin">Login with Auth0</a-button>
    </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()

let accessToken = route.query.access_token

function redirectToLogin() {
    location.href = process.env.VUE_APP_ADMIN_DOMAIN + '/api/login'
}

onMounted(() => {
    if (accessToken) {
        localStorage.setItem('access_token', accessToken)
        router.push({name: 'home'})
    } else {
        const access_token = localStorage.getItem('access_token')
        if (access_token) {
            router.push({name: 'home'})
        } else {
            router.push({name: 'login'})
        }
    }
})
</script>
