<template>
    <a-layout-sider
      v-model:collapsed="collapsedState.collapsed"
        width="230"
        class="menu"
        :trigger="null"
        collapsible
        :style="{
            backgroundColor: '#E7FBFF',
            borderRight: '4px solid #21798D'
        }"
    >
        <a-config-provider
            :theme="{
                components: {
                    Menu: {
                      colorItemText: '#21798D',
                      colorItemTextSelected: '#fff',
                      colorItemBgSelected: '#21798D',
                      colorItemTextHover: '#21798D'
                    },
                },
            }"
          >
            <div
                class="flex items-center justify-center"
                :class="{ 'px-[57px] py-[34px]' : !collapsedState.collapsed, 'px-2.5 py-[18px]' : collapsedState.collapsed }"
            >
                <img src="../../assets/logo.svg"  alt="logo" class="logo-img"/>
            </div>
            <a-menu
              :selectedKeys="[$route.meta.menuKey]"
              mode="inline"
              :inline-collapsed="collapsedState.collapsed"
              :style="{
                  backgroundColor: 'transparent'
              }"
            >
                <a-menu-item
                  key="approval"
                  v-if="authUser.is_admin || authUser.is_agency"
                  :style="{

                  }"
                  @click="pusher({ name: 'admin.approval' })"
                >
                  <template #icon>
                    <template v-if="collapsedState.collapsed">
                      <a-badge
                        v-if="(total > 0)"
                        dot
                        :offset="[-15, 18]"
                        color="#CD1225"
                      >
                        <dashboard-outlined />
                      </a-badge>
                      <dashboard-outlined v-else />
                    </template>
                    <dashboard-outlined v-else />
                  </template>
                    <span>承認待ち管理</span>
                    <span v-show="!collapsedState.collapsed" class="float-right">{{ total >= 10 ? '9+' : (total != 0 ? total : '') }}</span>
                </a-menu-item>
    <!--            <a-menu-item key="2" v-if="authUser.is_admin">-->
    <!--                <shop-filled />-->
    <!--                <span>代理店管理</span>-->
    <!--            </a-menu-item>-->
                <a-menu-item key="advertisers" v-if="authUser.is_admin" @click="$router.push({ name: 'advertisers' })">
                  <template #icon>
                    <shop-outlined />
                  </template>
                  <span>広告主管理</span>
                </a-menu-item>
                <a-menu-item key="item" @click="$router.push({ name: 'item.list' })">
                  <template #icon>
                    <code-sandbox-outlined />
                  </template>
                  <span>PR商品管理</span>
                </a-menu-item>
    <!--            <a-menu-item key="5" v-if="authUser.is_admin">-->
    <!--                <user-outlined />-->
    <!--                <span>ユーザー管理</span>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item key="6" v-if="authUser.is_admin">-->
    <!--                <share-alt-outlined />-->
    <!--                <span>PR管理</span>-->
    <!--            </a-menu-item>-->
    <!--            <a-menu-item key="7" v-if="authUser.is_admin">-->
    <!--                <notification-outlined />-->
    <!--                <span>プッシュ管理</span>-->
    <!--            </a-menu-item>-->
            </a-menu>
            <a-menu
                class="logout-btn"
                mode="inline"
                :selectedKeys="[$route.meta.menuKey]"
                :style="{
                      backgroundColor: 'transparent'
                  }"
            >
                <a-menu-item key="logout" @click="logout">
                  <template #icon>
                    <logout-outlined />
                  </template>
                  <span>ログアウト</span>
                </a-menu-item>
            </a-menu>
        </a-config-provider>
    </a-layout-sider>
</template>
<script setup>
import { LogoutOutlined,
    DashboardOutlined,
    // ShopFilled,
    ShopOutlined,
    CodeSandboxOutlined,
    // UserOutlined,
    // ShareAltOutlined,
    // NotificationOutlined,
} from "@ant-design/icons-vue";
import { useAuth } from "@/composables/useAuth";
import router from "@/routers";
import {onMounted, ref} from "vue";

const collapsedState = ref({});
const authUser = ref({});
const total = ref(0);

async function logout() {
    const authStore = useAuth();
    await authStore.logout();
    router.push('/auth/login');
}

function pusher(route) {
    router.push(route)
}

onMounted(async () => {
    const authStore = useAuth();
    authUser.value = authStore.authUser;
    total.value = authStore.total;
    collapsedState.value = authStore.collapsedState;
});
</script>
<style scoped>
.site-layout .site-layout-background {
    background: #fff;
}
[data-theme="dark"] .site-layout .site-layout-background {
    background: #141414;
}
.logout-btn {
    position: absolute;
    bottom: 10px;
}
.ant-layout-sider-children .ant-menu.ant-menu-inline-collapsed.logout-btn {
    width: 80px;
}
.logout-btn span {
    cursor: pointer;
}

.ant-badge {
  color: inherit;
}

@media (max-width: 768px) {
    .ant-layout-sider-collapsed.menu {
        margin-left: -80px;
    }

    .ant-layout-sider {
        position: fixed;
        height: 100vh;
        z-index: 100;
    }
}

.logo-img {
    max-width: 100%;
    height: auto;
}
</style>
